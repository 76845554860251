import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";
import Img from "gatsby-image";
import testpic from "../images/1.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  fade: true,
  slidesToShow: 1,
  arrows: true,
  dots: true,
};

function IndexPage({ data }) {
  return (
    <Layout>
      <SEO
        keywords={[
          `Hack Guard`,
          `Penetration Testing`,
          `CyberSecurity`,
          `Sudan`,
          `Malware Analysis`,
        ]}
        title="Home"
      />

      <section className="text-center">
        <div>
          <p className="block w-1/2 mx-auto my-8 bd-red-200">
            <Slider {...settings} className="">
              <div>
                <Img fluid={data.gatsbytestimage.childImageSharp.fluid} />
                <h1 className="font-bold text-black text-3xl text-bold">
                  Application Penetration Testing
                </h1>
              </div>
              <div>
                <Img fluid={data.gatsbytestimagetwo.childImageSharp.fluid} />
                <h1 className="font-bold text-black text-3xl text-bold">
                  Network Penetration Testing
                </h1>
              </div>
              <div>
                <Img fluid={data.gatsbytestimagethree.childImageSharp.fluid} />
                <h1 className="font-bold text-black text-3xl text-bold">
                  Source Code Review
                </h1>
              </div>
              <div>
                <Img fluid={data.gatsbytestimagefour.childImageSharp.fluid} />
                <h1 className="font-bold text-black text-3xl text-bold">
                  Malware Analysis
                </h1>
              </div>
            </Slider>
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    gatsbytestimage: file(relativePath: { eq: "44.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gatsbytestimagetwo: file(relativePath: { eq: "33.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gatsbytestimagethree: file(relativePath: { eq: "11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gatsbytestimagefour: file(relativePath: { eq: "55.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
